import { mutate } from 'swr';
import FormSubmissionB2B from '../../CheckoutPage/Models/FormSubmissionB2B.interface';
import FormSubmissionB2C from '../../CheckoutPage/Models/FormSubmissionB2C.interface';

let checkoutUrl = '';
let litiumContext = '';
let cartUrl = '';

export async function PlaceB2COrder(
  data: FormSubmissionB2C,
  litiumContext: string,
  languageRoute: string,
  setSubmitIsLoading?: (value: boolean) => void
) {
  setSubmitIsLoading && setSubmitIsLoading(true);
  checkoutUrl = `/api/${languageRoute}/checkout/`;
  litiumContext = JSON.stringify({
    channelSystemId: litiumContext,
  });
  const res = await fetch(`${checkoutUrl}PlaceB2COrder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': litiumContext,
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    const data = await res.json();
    setSubmitIsLoading && setSubmitIsLoading(false);
    return data;
  }
}

export async function PlaceB2BOrder(
  data: FormSubmissionB2B,
  channelId: string,
  languageRoute: string,
  setSubmitIsLoading?: (value: boolean) => void
) {
  setSubmitIsLoading && setSubmitIsLoading(true);
  checkoutUrl = `/api/${languageRoute || 'en'}/checkout/`;
  litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  const res = await fetch(`${checkoutUrl}PlaceOrder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': litiumContext,
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    const data = await res.json();
    setSubmitIsLoading && setSubmitIsLoading(false);
    return data;
  }
  setSubmitIsLoading && setSubmitIsLoading(false);
}

export async function AddDiscountCode(
  discountCode: string,
  litiumContext: string,
  languageRoute: string,
  setSubmitIsLoading?: (value: boolean) => void
) {
  setSubmitIsLoading && setSubmitIsLoading(true);
  cartUrl = `/api/${languageRoute}/Cart/`;
  checkoutUrl = `/api/${languageRoute}/checkout/`;
  litiumContext = JSON.stringify({
    channelSystemId: litiumContext,
  });
  const response = await fetch(
    `${checkoutUrl}SetDiscountCode?discountCode=${discountCode}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': litiumContext,
      },
    }
  );
  if (response.ok) {
    const { cart, res } = await response.json();

    setSubmitIsLoading && setSubmitIsLoading(false);
    mutate(`${cartUrl}GetCart`, cart, false);
    return res;
  }
}
