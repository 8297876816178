import { TrashIcon } from '../../Atoms/Icons';
import Image from '../../Atoms/Image/Image';
import CartItemQuantitySelector from '../../Atoms/Quantity/CartItemQuantitySelector';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { RemoveFromCart } from '../../Pages/CartPage/Cart';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';

type PropTypes = {
  lineItem: VariationModel;
};

function CheckoutProductCard({ lineItem }: PropTypes) {
  const {
    'productItem/size': sizeLabel,
    'common/articleNumber': articleNumberLabel,
    'checkoutPage/subtotal': subTotalLabel,
    'productItem/notInStock': notInStockLabel,
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();
  const { channelId } = useCurrentPage<PageModelBase>();

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <Root>
      <ImageAndAttributeArea>
        <ImageWrapper href={lineItem.to}>
          <StyledImage src={lineItem.image?.src} alt={lineItem.image?.alt} />
        </ImageWrapper>
        <AttributeArea>
          <NameAndPrice>
            <Name>{lineItem.name}</Name>
            {!isDesktop ? (
              <span>{lineItem.totalPrice}</span>
            ) : (
              <IconWrapper
                onClick={() =>
                  RemoveFromCart(lineItem.code, languageRoute, litiumContext)
                }
              >
                <TrashIcon size="trashCan" />
              </IconWrapper>
            )}
          </NameAndPrice>
          <ArticleNumber>
            {articleNumberLabel}: {lineItem.articleNumber}
          </ArticleNumber>
          {lineItem.priceModel &&
            (lineItem.priceModel.campaignPrice?.priceFormatted ? (
              <span>{lineItem.priceModel.campaignPrice?.priceFormatted}</span>
            ) : (
              <span>{lineItem.priceModel.price?.priceFormatted}</span>
            ))}
          {lineItem.size && (
            <Attribute>
              {sizeLabel}: {lineItem.size}
            </Attribute>
          )}
          {!lineItem.inStock && !lineItem.hideStockStatus && (
            <Attribute error={true}>{notInStockLabel}</Attribute>
          )}
          {isDesktop && (
            <QuantitySelectorWrapper>
              <CartItemQuantitySelector
                lineItem={lineItem}
                isCheckout={true}
                canUpdateCart={false}
              />
              <Total>
                <span>
                  {subTotalLabel}: {lineItem.totalPrice}
                </span>
              </Total>
            </QuantitySelectorWrapper>
          )}
        </AttributeArea>
      </ImageAndAttributeArea>
      {!isDesktop && (
        <QuantitySelectorWrapper>
          <CartItemQuantitySelector
            lineItem={lineItem}
            isCheckout={true}
            canUpdateCart={false}
          />
          <IconWrapper
            onClick={() =>
              RemoveFromCart(lineItem.code, languageRoute, litiumContext)
            }
          >
            <TrashIcon size="trashCan" />
          </IconWrapper>
        </QuantitySelectorWrapper>
      )}
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  py: 6,
  borderBottom: '1px solid $grey100',
});

const AttributeArea = styled('div', {
  fs: 5,
  mb: 6,
  ml: 4,
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  '@mediaMinLarge': {
    gap: '4px',
    ml: 8,
    fs: 6,
  },
});

const Total = styled('div', {
  display: 'flex',
  fs: 6,
  fontWeight: '$fw700',
  alignItems: 'flex-end',
});

const ImageAndAttributeArea = styled('div', {
  display: 'flex',
});

const IconWrapper = styled('button', {
  mb: 0.5,
  mt: 'auto',
});

const NameAndPrice = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: '$fw700',
  lineHeight: '16px',
  '@mediaMinLarge': {
    lineHeight: '24px',
  },
});

const QuantitySelectorWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  '@mediaMinLarge': {
    mt: 5,
  },
});

const Name = styled('span', {
  maxW: '65%',
  '@mediaMinLarge': {
    fs: 7,
  },
});

const ArticleNumber = styled('div', {
  fs: 3.5,
  lineHeight: '14px',
  '@mediaMinLarge': {
    fs: 5,
  },
});

const Attribute = styled('span', {
  fs: 6,
  lineHeight: '18px',
  variants: {
    error: {
      true: {
        color: '$errorText',
      },
    },
  },
});

const ImageWrapper = styled('a', {
  wh: 10,
  br: 2,
  overflow: 'hidden',
  '@mediaMinLarge': {
    wh: 18,
  },
});

const StyledImage = styled(Image, {
  objectFit: 'contain',
  h: '100%',
});

export default CheckoutProductCard;
