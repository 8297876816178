import { useState } from 'react';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import { CheckmarkIcon, CloseIcon } from '../../Atoms/Icons';
import { H1 } from '../../Atoms/Typography/Headings/Heading';
import KexCart from '../../Cart/Models/KexCart.interface';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import useMedia from '../../Shared/Hooks/useMedia';
import KexInput from '../../Shared/Input/KexInput';
import { KexInputValidation } from '../../Shared/Input/KexInputValidation';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';
import { animationPathsIn } from '../../Theme/Settings/keyframes';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import CartPriceSummary from './CartPriceSummary';
import { AddDiscountCode } from './CheckoutApi';
import CheckoutProductCard from './CheckoutProductCard';
import ClearCartModal from '../../Organisms/ClearCartModal/ClearCartModal';
import { TrashClearCartIcon } from '../../Atoms/Icons';

type PropType = {
  cart: KexCart;
  heading: string;
};

function CheckoutProductListing({ cart, heading }: PropType) {
  const { languageRoute } = useAppSettingsData();
  const { channelId } = useCurrentPage<PageModelBase>();

  const {
    'checkoutPage/items': itemsLabel,
    'checkoutPage/addDiscountCode': addDiscountCodeLabel,
    'checkoutPage/discountCodeInformation': discountCodeInformationLabel,
    'checkoutPage/addCode': addCodeLabel,
    'checkoutPage/discountCode': discountCodeLabel,
    'checkoutPage/vatInfo': vatInfoLabel,
    'common/cart': cartText,
    'common/clearCart': clearCartText,
  } = useTranslationData();

  const [addDiscount, setAddDiscount] = useState<boolean>(false);
  const [discountCode, setDiscountcode] = useState<string>('');
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [addedDiscountCodes, setAddedDiscountCodes] = useState<string[]>();
  const [showClearCartModal, toggleClearCartModal] = useState(false);

  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  async function submitDiscountCode() {
    const data = await AddDiscountCode(
      discountCode,
      channelId,
      languageRoute,
      setSubmitIsLoading
    );
    if (data) {
      if (data.success) {
        setAddedDiscountCodes(data.usedDiscountCodes);
      } else {
        setErrorMessage(data.message);
      }
    }
  }

  const onInputChange = (value: string) => {
    setDiscountcode(value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <>
      <TitleWrapper>
        <CheckoutPageTitle noMargin>{heading}</CheckoutPageTitle>
      </TitleWrapper>
      <Top>
        {!isDesktop ? (
          <>
            <Flex>
              <CheckoutSectionTitle>{cartText}</CheckoutSectionTitle>
              <ItemsText>
                {cart?.numberOfItems} {itemsLabel}
              </ItemsText>
            </Flex>
            <VatInfo>{vatInfoLabel}</VatInfo>
          </>
        ) : (
          <Flex>
            <div>
              <CheckoutSectionTitle>{cartText}</CheckoutSectionTitle>{' '}
              <ItemsText>
                {cart?.numberOfItems} {itemsLabel}
              </ItemsText>
            </div>
            <VatInfo>{vatInfoLabel}</VatInfo>
          </Flex>
        )}
      </Top>
      <ProductCardsContainer>
        {cart &&
          cart.lineItems &&
          cart.lineItems.map((lineItem: VariationModel) => (
            <CheckoutProductCard
              key={lineItem.articleNumber}
              lineItem={lineItem}
            />
          ))}
        {cart && cart.lineItems?.length > 0 && (
          <TopClearCart>
            <button
              onClick={() => {
                toggleClearCartModal(true);
              }}
            >
              <IconWrapper>
                <TrashClearCartIcon size="clearCartTrashCan" />
              </IconWrapper>
              <span>{clearCartText}</span>
            </button>
          </TopClearCart>
        )}
        {cart && cart.lineItems && (
          <CartPriceSummaryContainer>
            {cart.totalDiscount && (
              <DiscountContainer>
                <DiscountToggleButton
                  onClick={() => setAddDiscount(!addDiscount)}
                  size="s"
                >
                  {addDiscount && <CloseIcon color="white" size="xs" />}
                  <ButtonText isOpen={addDiscount}>
                    {!addDiscount ? addDiscountCodeLabel : 'Close'}
                  </ButtonText>
                </DiscountToggleButton>
                {addDiscount && (
                  <AddDiscount>
                    <span>{discountCodeInformationLabel}</span>
                    <InputContainer>
                      <KexInputValidation>
                        <KexInput
                          title={addCodeLabel}
                          placeholder={discountCodeLabel}
                          onChange={(value: string) => onInputChange(value)}
                          onKeyDown={(e: any) =>
                            e.key === 'Enter' && submitDiscountCode()
                          }
                        />
                      </KexInputValidation>
                      <SubmitButtonWrapper>
                        <SubmitDiscountButton
                          onClick={() => submitDiscountCode()}
                          size={isMobile ? 's' : 'input'}
                          isLoading={submitIsLoading}
                          disabled={!discountCode}
                        >
                          <ButtonText>{addCodeLabel}</ButtonText>
                        </SubmitDiscountButton>
                      </SubmitButtonWrapper>
                    </InputContainer>
                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                    {addedDiscountCodes && (
                      <AddedDiscounts>
                        {addedDiscountCodes.map((code: string) => (
                          <AddedDiscountCode>
                            <CheckmarkIcon color="green" />
                            {code}
                          </AddedDiscountCode>
                        ))}
                      </AddedDiscounts>
                    )}
                  </AddDiscount>
                )}
              </DiscountContainer>
            )}
            <CartPriceSummary cart={cart} />
          </CartPriceSummaryContainer>
        )}
        {showClearCartModal && (
          <ClearCartModal
            overlay={true}
            toggle={() => toggleClearCartModal(false)}
            clearCart={true}
          />
        )}
      </ProductCardsContainer>
    </>
  );
}

const ProductCardsContainer = styled('div', {
  w: '100%',
});

const Top = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pb: 2.5,
  borderBottom: '1px solid $grey100',
  '@mediaMinLarge': {
    pb: 4,
  },
});

export const CheckoutSectionTitle = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
  '@mediaMinLarge': {
    fs: 9,
  },
});

const ItemsText = styled('span', {
  fs: 5,
  '@mediaMinLarge': {
    fs: 6,
    ml: 2,
  },
});

const VatInfo = styled('span', {
  fs: 4,
  lineHeight: '16px',
  '@mediaMinLarge': {
    fs: 5,
  },
});

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ErrorText = styled('span', {
  color: '$errorText',
  mb: 1,
});

const TitleWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 4,
  '@mediaMinLarge': {
    mb: 12,
  },
});

const AddedDiscounts = styled('div', {});

const AddedDiscountCode = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$green',
});

const SubmitButtonWrapper = styled('div', {
  flexBasis: '30%',
  w: '100%',
});

const CartPriceSummaryContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mt: 4,
  '@mediaMaxMedium': {
    flexDirection: 'column',
  },
  '@mediaMinLarge': {
    mt: 16,
  },
});

const InputContainer = styled('div', {
  display: 'flex',
  g: 4,
  mt: 2,
  '@mediaMaxMedium': {
    flexDirection: 'column',
    mb: 4,
    g: 3,
  },
  '@mediaMinMedium': {
    alignItems: 'center',
  },
});

const SubmitDiscountButton = styled(CtaButton, {
  h: '100%',
  flexBasis: '30%',
  '@mediaMaxMedium': {
    h: 9,
  },
});

const DiscountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  g: 3,
  '@mediaMaxMedium': {
    alignSelf: 'flex-start',
  },
  '@mediaMinMedium': {
    maxW: '50%',
    w: '100%',
  },
});

const DiscountToggleButton = styled(CtaButton, {
  h: '100%',
  display: 'flex',
  w: 'fit-content',
});

const AddDiscount = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  animation: `${animationPathsIn} ${timings.oneHalf}`,
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: '1',
});

const ButtonText = styled('span', {
  px: 4,
  fs: 8,
  variants: {
    isOpen: {
      true: {
        pl: 2,
      },
    },
  },
});

const CheckoutPageTitle = styled(H1, {
  display: 'flex',
  alignItems: 'center',
  fs: 10,
  fontWeight: '$fw700',
  mt: 0,
  lineHeight: '30px',
  '@mediaMaxLarge': {
    variants: {
      noMargin: {
        true: {
          my: 0,
        },
      },
    },
  },
  '@mediaMinLarge': {
    fs: 12,
    lineHeight: '32px',
  },
});

const IconWrapper = styled('div');

const TopClearCart = styled('div', {
  display: 'flex',
  pt: 6,
  pb: 6,
  mb: 6,
  justifyContent: 'flex-end',
  span: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '1px',
  },
  borderBottom: '1px solid $grey100',
  svg: {
    ml: 0,
    mr: 2,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
      position: 'relative',
      top: '2px',
      textTransform: 'uppercase',
    },
  },
});

export default CheckoutProductListing;
