import KexCart from '../../Cart/Models/KexCart.interface';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';

type PropType = {
  cart: KexCart;
};

function CartPriceSummary({ cart }: PropType) {
  const {
    'checkoutPage/subtotal': subTotalLabel,
    'checkoutPage/totalsum': totalSumLabel,
    'checkoutPage/vatTotal': vatLabel,
    'common/freightInformationLabel': freightInformationLabel,
  } = useTranslationData();
  return (
    <>
      {cart && cart.lineItems && (
        <PriceSummaryContainer>
          <PriceSummaryWrapper>
            <Flex>
              <span>{subTotalLabel}</span>
              <Value>{cart.totalPriceForItems}</Value>
            </Flex>
            <Flex>
              <span>{vatLabel}</span>
              <Value>{cart.totalVATPrice}</Value>
            </Flex>
          </PriceSummaryWrapper>
          <TotalSum>
            <span>{totalSumLabel}</span>
            <span>{cart.totalPrice}</span>
          </TotalSum>
          <FreightInfo>
            <span>{freightInformationLabel}</span>
          </FreightInfo>
        </PriceSummaryContainer>
      )}
    </>
  );
}

export default CartPriceSummary;

const PriceSummaryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  fs: 5,
  '@mediaMinLarge': {
    fs: 6,
    w: '50%',
    ml: 'auto',
  },
});

const FreightInfo = styled('div', {
  mt: 2,
});

const PriceSummaryWrapper = styled('div', {
  lineHeight: '16px',
  pb: 1.5,
  borderBottom: '1px solid $grey100',
  '@mediaMinLarge': {
    pb: 6,
  },
});

const TotalSum = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fs: 6,
  fontWeight: '$fw700',
  mt: 4,
  '@mediaMinLarge': {
    fs: 9,
    mt: 6,
  },
});

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  w: '100%',
  mb: 2,
});

const Value = styled('span', {
  fontWeight: '$fw700',
  '@mediaMinLarge': {
    fs: 7,
  },
});
